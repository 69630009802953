@import "mixins";

.main-container {
  max-width: 1172px;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  display: block;
  @include lg-max() {
    max-width: 968px;
  }
  @include md-max() {
    max-width: 712px;
  }
  @include sm-max() {
    max-width: 532px;
  }
  @include xs-max() {
    max-width: 95%;
  }
}

.main-admin-container {
  padding: 0;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.main-container--row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-space-around {
    justify-content: space-around;
  }

  &.align-items-center {
    align-items: center;
  }

  &--column {
    &.justify-start, &.justify-center, &.justify-end {
      display: flex;
    }

    &.justify-start {
      justify-content: flex-start;
    }

    &.justify-center {
      justify-content: center;
    }

    &.justify-end {
      justify-content: flex-end;
    }
  }
}

.main-col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.main-col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.main-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.main-col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.main-col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.main-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.main-col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.main-col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.main-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.main-col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.main-col-11 {
  flex: 0 0 91.666666666%;
  max-width: 91.666666666%;
}

.main-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}


//lg-small
@include lg-max() {

  .main-col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .main-col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .main-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .main-col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .main-col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .main-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .main-col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .main-col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .main-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .main-col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .main-col-lg-11 {
    flex: 0 0 91.666666666%;
    max-width: 91.666666666%;
  }

  .main-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

//md-small
@include md-max() {
  .main-col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .main-col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .main-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .main-col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .main-col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .main-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .main-col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .main-col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .main-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .main-col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .main-col-md-11 {
    flex: 0 0 91.666666666%;
    max-width: 91.666666666%;
  }

  .main-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

//sm-small
@include sm-max() {
  .main-col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .main-col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .main-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .main-col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .main-col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .main-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .main-col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .main-col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .main-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .main-col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .main-col-sm-11 {
    flex: 0 0 91.666666666%;
    max-width: 91.666666666%;
  }

  .main-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

//xs-small
@include xs-max() {
  .main-col-xs-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .main-col-xs-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .main-col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .main-col-xs-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .main-col-xs-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .main-col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .main-col-xs-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .main-col-xs-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .main-col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .main-col-xs-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .main-col-xs-11 {
    flex: 0 0 91.666666666%;
    max-width: 91.666666666%;
  }

  .main-col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
