@import "text_variables";

// screens width
$grid-breakpoints-xs-max: 576px;
$grid-breakpoints-sm-max: 768px;
$grid-breakpoints-md-max: 992px;
$grid-breakpoints-lg-max: 1220px;

@mixin xs-max {
  @media (max-width: $grid-breakpoints-xs-max) {
    @content;
  }
}

@mixin sm-max {
  @media (max-width: $grid-breakpoints-sm-max) {
    @content;
  }
}

@mixin md-max {
  @media (max-width: $grid-breakpoints-md-max) {
    @content;
  }
}

@mixin lg-max {
  @media (max-width: $grid-breakpoints-lg-max) {
    @content;
  }
}

// spaces

$space-amounts: (0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128, 136, 144, 152, 160, 168, 200, 216, 240, 248, 352, 376, 392, 442, 450); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $space-amounts {
  @each $side in $sides {
    .main-m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .main-m-#{$space} {
      margin: #{$space}px !important;
    }

    .main-p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }

    .main-p-#{$space} {
      padding: #{$space}px !important;
    }
  }
}


//fonts

@mixin font-size-xextra {
  font-size: $font-size-xextra;
  line-height: 48px;
  font-family: $font-family;
  @include md-max() {
    font-size: 38px;
  }
  @include sm-max() {
    font-size: $font-size-xlarge;
    line-height: 32px;
  }
  @include xs-max() {
    font-size: 20px;
    line-height: 24px;
  }
}

@mixin font-size-extra {
  font-size: $font-size-extra;
  line-height: 36px;
  font-family: $font-family;
  @include lg-max() {
    font-size: $font-size-xlarge;
    line-height: 32px;
  }
  @include sm-max() {
    font-size: 20px;
    line-height: 24px;
  }
  @include xs-max() {
    @include font-size-medium();
    line-height: 22px;
  }
}

@mixin font-size-xlarge {
  font-size: $font-size-xlarge;
  line-height: 28px;
  font-family: $font-family;
  @include lg-max() {
    @include font-size-medium();
    line-height: 22px;
  }
  @include sm-max() {
    font-size: $font-size-base;
    line-height: 18px;
  }
  @include xs-max() {
    font-size: $font-size-small;
    line-height: 16px;
  }
}

@mixin font-size-large {
  font-size: $font-size-large;
  line-height: 26px;
  font-family: $font-family;
  @include lg-max() {
    font-size: $font-size-medium;
    line-height: 22px;
  }
  @include md-max() {
    font-size: $font-size-base;
    line-height: 18px;
  }
  @include sm-max() {
    font-size: $font-size-small;
    line-height: 16px;
  }
}

@mixin font-size-big {
  font-size: $font-size-big;
  line-height: 24px;
  font-family: $font-family;
  @include lg-max() {
    font-size: $font-size-medium;
    line-height: 22px;
  }
  @include md-max() {
    font-size: $font-size-small;
    line-height: 16px;
  }
}

@mixin font-size-medium {
  font-size: $font-size-medium;
  line-height: 22px;
  font-family: $font-family;
  @include lg-max() {
    font-size: $font-size-base;
    line-height: 18px;
  }
  @include md-max() {
    font-size: $font-size-small;
    line-height: 16px;
  }
  @include sm-max() {
    font-size: $font-size-xsmall;
    line-height: 14px;
  }
  @include xs-max() {
    font-size: $font-size-small;
    line-height: 16px;
  }
}

@mixin font-size-base {
  font-size: $font-size-base;
  line-height: 18px;
  font-family: $font-family;
  @include lg-max() {
    font-size: $font-size-small;
    line-height: 16px;
  }
  @include md-max() {
    font-size: $font-size-xsmall;
    line-height: 14px;
  }
  @include sm-max() {
    font-size: 8px;
    line-height: 12px;
  }
  @include xs-max() {
    font-size: $font-size-xsmall;
    line-height: 14px;
  }
}

@mixin font-size-small {
  font-size: $font-size-small;
  line-height: 16px;
  font-family: $font-family;
  @include md-max() {
    font-size: $font-size-xsmall;
    line-height: 14px;
  }
  @include sm-max() {
    font-size: 8px;
    line-height: 12px;
  }
  @include xs-max() {
    font-size: $font-size-xsmall;
    line-height: 14px;
  }
}
