/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2020 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      MadeType
 * License URL: https://www.fontspring.com/licenses/madetype/webfont
 *
 *
 */

@font-face {
  font-family: "made_tommy";
  src: url("made_tommy_bold-webfont.woff2") format("woff2"),
    url("made_tommy_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "made_tommy";
  src: url("made_tommy_light-webfont.woff2") format("woff2"),
    url("made_tommy_light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
