@import "./assets/fonts/stylesheet.css";
@import "~@angular/material/theming";
@import "~flag-icons/css/flag-icons.min.css";
@import "assets/styles/container";

$dark-basic: mat-palette($mat-light-green);
$dark-primary: mat-palette($mat-light-green);
$dark-accent: mat-palette($mat-light-green, A400);
$dark-warn: mat-palette($mat-red);
$dark-disabled: mat-palette($mat-grey);
$dark-link: mat-palette($mat-light-green);
$dark-theme: mat-dark-theme(
  (
    color: (
      basic: $dark-basic,
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
      disabled: $dark-disabled,
      link: $dark-link,
    ),
  )
);

// Apply the dark theme by default
@include angular-material-theme($dark-theme);
$dark-typography: mat-typography-config(
  $font-family: "made_tommy, Roboto",
);
@include mat-core($dark-typography);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: made_tommy, Roboto, "Helvetica Neue", sans-serif;
  background-color: black;
  font-size: 0.7rem;
  color: white;
}
* {
  box-sizing: border-box;
}

.snack-bar-error {
  background-color: #FCACA3;
}

.mat-select-panel {
  margin-top: 28px;
  background-color: rgba(black, 0.95);
  border: 2px solid rgba(#BBAD81, 0.8);
  .mat-option {
    text-align: center;

    &:not(:last-child) {
      border-bottom: 2px solid rgba(#BBAD81, 0.8);
    }
  }
  .mat-option:hover {
    background-color: #BBAD81;
  }
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mat-toggle-buttons {
  .mat-raised-button {
    border-radius: 0;
  }
  .mat-raised-button:first-child {
    border-radius: 4px 0 0 4px;
  }
  .mat-raised-button:last-child {
    border-radius: 0 4px 4px 0;
  }
}

#data-system-chat-bot-widget {
  position: fixed;
  bottom: 0;
  right: 0;
}

.mat-raised-button.mat-accent {
  background-color: #BBAD81 !important;
  color: white;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #BBAD81;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #BBAD81;
}

.custom-dialog-style {
  .mat-dialog-container {
  background-color: unset;
  box-shadow: unset;
  }
}


.menu-dialog-style {
  .mat-dialog-container {
    background-color: #1E0E0BCC;
    box-shadow: unset;
    padding: 0;
  }
}
